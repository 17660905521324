// var hTableModels = []
// var hTableMainModels = []
var currentTab = "";
var dataTableData;

/*
$(function(){
    $('.hTableAjaxLink').on('click',function(e){
        e.preventDefault();
        e.stopImmediatePropagation();
        var url = e.target.href;
        $.get(url, function(data, status){
            if ( status == 'success' ) {
                console.log( "success '" + status + "'" );
            } else {
                console.log( "fail" );
            }
        });
    })
});*/



/*! hTable 1.0
 * ==========================
 */
(function ($) {
    function processValue( oldModel, model, row, columnName, value, columnIndex ) {

        if (typeof oldModel[columnName] !== 'undefined') {
            if (typeof oldModel[columnName]['widget'] !== 'undefined') {
                var widget = oldModel[columnName]['widget'];
                if (widget == 'actionColumn') {
                    value = processActionColumn(model, oldModel, row, columnName, value);
                }
                // if (widget == 'checkboxColumn') {
                //     value = processCheckboxColumn(oldModel, row, columnName, value);
                // }
            }
        }
        if (model.columns) {
            for (var i = 0; i < model.columns.length; i++) {
                var obj = model.columns[i];
                if (obj.name == columnName) {
                    var type = obj.type;
                    //  console.log( type );
                    if (type == 'HIconColumn') {
                        value = processIconColumn(model, row, columnName, value, columnIndex);
                    }
                    if (type == 'HCheckBoxColumn') {
                        value = processCheckboxColumn(model, row, columnName, value, columnIndex);
                    }
                }
            }
        }

        return (value);
    }

    function processIconColumn( model, row, columnName, value, columnIndex ) {
        var fixedIconName  = '';
        var visibleText = true;
        var styleOnly = false;
        var textColumName = '';
        if (model.columns) {
            for (var i = 0; i < model.columns.length; i++) {
                var obj = model.columns[i];
                if (obj.name == columnName) {
                    if ( obj.fixedIconName != ''  && obj.fixedIconName != null ) {
                        fixedIconName = obj.fixedIconName;
                    }
                    if ( obj.visibleText == 'false' ){
                        visibleText = false;
                    }
                    textColumName = obj.textName;
                    styleOnly = ( obj.styleOnly == 'true' );
                }
            }
        }

        var html = '<div class="iconColumn">';
        if ( styleOnly){
            if ( visibleText ) {
                var clazz = row[ columnName + '-icon' ];
                html += '<div class="iconColumnText has-icon '+clazz+'">';
                html += value;
                html += '</div>';
            } else{
                /** virtual icon using a div **/
                var clazz = row[ columnName + '-icon' ];
                html += '<div class="' + clazz + '"></div>';
            }
        } else {
            var icon = row[ columnName + '-icon' ];
            icon = fixedIconName == '' ? icon : fixedIconName;
            if ( icon != '') {
                html = '<div class="' + columnName + 'Column iconColumn flexContainer">';
                html += '<div class="iconColumnSVG">';

                /* icon class uses row data unless no icon data is present (Mapper not used) */
                var iconClass = "icon-" + icon;
                /* fixed icon name always overrides data from row object */
                html += createSVGHtml(iconClass, icon);
                html += '</div>';
            } else {
                html = '<div></div>';
            }
            if ( visibleText ) {
                html += '<div class="iconColumnText">';
                html += value;
                html += '</div>';
            }
        }


        html += '</div>';

        return( html );
    }

    function createSVGHtml( iconClass, icon ){
        return( '<svg class="icon-' + icon + '"><use xlink:href="/img/icons.svg#icon-' + icon + '"></use></svg>' );
    }

    function processCheckboxColumn( model, row, columnName, value, columnIndex ) {
        var selected = row[ columnName + '-selected' ];
        var column = getColumnFromModel( model, columnName );
        var html = '<div class="form-field"><div class="field-checkbox"><label><input type="checkbox" class="focbed hCheckbox ';
        html += column.ajax == 'true' ? ' hCheckboxAjax' : '';
        html += '"';
        if ( column.ajax ){
            html += ' data-evtjcs="' + column.clsID +  '"';
        }
        html += selected ? ' checked' : '';
        html += '><span class="focb" title=""></span></label></div></div>';
        return( html );
    }

    function processActionColumn( model, oldModel, row, columnName, value ){
        var html = '<div>\n';

        var column = getColumnFromModel( model, columnName )

        for( var obj of column.links ) {
            var url = '';
            var modalType = '';
            if (obj.type == 'AjaxLink' || obj.type == 'DeleteLink') {
                url = window.contextPath + "/clickAction.json";
            } else if (obj.type == 'UrlLink' || obj.type == 'AjaxModalLink') {
                url = window.contextPath + obj.url;
                if (obj.type == 'AjaxModalLink'){
                    modalType = obj.modalType;
                }
            }
            var text = obj.text;
            var icon = obj.icon;


            var params = obj.allParams;
      //      console.log( params );
            for (key in params) {
                if (params.hasOwnProperty(key)) {
        //            console.log(key + " = " + params[key]);
                    url = addUrlParameter( url, key, params[key], row );

                }
            }
            if ( model.pkName ){
                url = addUrlParameter( url, model.pkName, row[model.pkName] );
            }
            //need to do jquery event loading after table has rendered so just us onclick for now

           // console.log( 'url: ' + obj.text + " u: " + url + '' );

            var content = text;
//            console.log( settings );
//            settings.iconCallback();

            var customIcon = getGlobalListLinkIcon(model.tableID, obj.name, row);
            var linkable = isGlobalListLinkUrl(model.tableID, obj.name, row);
            if (customIcon != '') {
                icon = customIcon;
            } else {
                try {
                    if (obj.name != '') {
                        customIcon = getListLinkIcon(obj.name, row);
                        if (customIcon != '') {
                            icon = customIcon;
                        }
                        else {
                            customIcon = getGlobalListLinkIcon(model.tableID, obj.name, row);
                        }
                        linkable = isListLinkUrl(obj.name, row);
                    }
                } catch(err) {}
            }


            if (icon != "") {
                content = createSVGHtml( icon, icon );
            }
//            console.log( 'obj.type: ' + obj.type + '' );
            var a = '';
            if (obj.type == 'ExpandLink') {
                a = '<a href="#" class="expand">'
//                html += '<a href="#" class="expand">' + content + '</a>\n';
            } else if (obj.type == 'AjaxLink'){
                a = '<a href="#" onclick="linkClicked( \'' + url + '\' );">';
                //html += '<a href="#" onclick="linkClicked( \'' + url + '\' );">' + content + '</a>\n';
            } else if (obj.type == 'AjaxModalLink'){
                a = '<a href="' + url + '" class="modal-link" data-modalType="'+modalType+'">';
                //html += '<a href="' + url + '" class="modal-link" data-modalType="'+modalType+'">' + content + '</a>\n';
            } else if (obj.type == 'DeleteLink'){
                var href = obj.confirm ? '#warningModal' : '#';
                var cssClass= obj.confirm ? 'deleteLink modal-link' : 'deleteLink';
                var modalType= obj.confirm ? ' data-modalType="warningModal" ' : '';
                a = '<a href="' + href + '" data-url="' + url + '" class="'+cssClass+'" data-suffix="' + obj.suffixText + '" data-confirm="' + obj.confirm + modalType +'">';
                //html += '<a href="' + href + '" data-url="' + url + '" class="'+cssClass+'" data-suffix="' + obj.suffixText + '" data-confirm="' + obj.confirm + modalType +'">' + content + '</a>\n';
            }else if (obj.type == 'UrlLink'){
                a = '<a href="' + url + '">';
                //html += '<a href="' + url + '">' + content + '</a>\n';
            }
            if ( linkable ){
                html += a + content + '</a>\n';
            } else {
                html += content;
            }
//            html = content;
        }
        return( html );

    }


    function linkClicked( url ){
        console.log( 'linkClicked' );
        $.get(url, function(data, status){
            if ( status == 'success' ) {
            } else if ( jsonResponse.redirectURL ){
                console.log( 'redirectURL: ' + jsonResponse.redirectURL + '' );
                window.location.href = jsonResponse.redirectURL;
            }
        });
    }

    function tabClicked( name ){
        // console.log( 'tabClicked: ' + url );
        currentTab = name;
    }

    function processTemplate( templateID, json ){
        console.log( 'templateID: ' + templateID + '' );
        var html = $( '#' + templateID ).html();
        var done = false;
        var from = 0;
        while( !done ){
            var start = html.indexOf( '@{', from );
            if ( start != -1 ){
                var end = html.indexOf( '}', start );
                var sub = html.substring( start, end + 1 )
                console.log( 'sub: ' + sub + '' );
                var property = sub.replace( '@{' , '');
                property = property.replace( '}' , '');
                if (typeof json[property] !== 'undefined' && json[property] != '') {
                    html = html.replace( sub, json[property] );
                } else {
                    from = start + 1;
                }
            } else {
                done = true;
            }
        }
        return( html );
    }

    /** cleans up the template by removing any @{tags} that have not been replaced **/
    function finishTemplate( html ){
        var done = false;
        var from = 0;
        while( !done ){
            var start = html.indexOf( '@{' );
            if ( start != -1 ){
                var end = html.indexOf( '}', start );
                var sub = html.substring( start, end + 1 )
                html = html.replace( sub, '' );
            } else {
                done = true;
            }
        }
        return( html );
    }

    function expandOrContract(  tr, row, columnTotal ){
        var rowID = tr.prop( 'id' );
        var expandID = rowID.replace( "row", "expand" );
        var expandTr = $('#' + expandID);

        console.warn(expandID)
        console.log($('#' + expandID).length)

        if ( $('#' + expandID).length == 0 ){
            var html = processTemplate( 'expandTemplate', row.data() );
            html = '<tr id="' + expandID + '"><td colspan="' + columnTotal + '">' + html + '</td></tr>';
            tr.addClass( 'open' );
            try {
                html = expandRow( rowID, html, row.data() );
            } catch(err) {}
            html = finishTemplate( html );
            $(html).insertAfter( ( tr ) );
            try {
                html = expandRowComplete( rowID, row.data() );
            } catch(err) {
                console.log("EXPAND ROW NOT COMPLETE")
            }
        } else {
            if ( tr.hasClass( 'open' ) ){
                expandTr.addClass( 'hide' );
                tr.removeClass( 'open' );
            } else {
                expandTr.removeClass( 'hide' );
                tr.addClass( 'open' );
            }
        }
        console.log(row)
    }

    function deleteClicked( src, model, row, hTableList ){
        var url = src.data('url');
        var suffix = src.data('suffix');
        var confirm = src.data('confirm');

        //     console.log( 'deleteClicked' )
        var pkName = model.pkName;
        var pkValue = row[pkName];
        url = addUrlParameter( url, 'pkValue', pkValue )
   //     console.log( "url '" + url + "'" );

        if ( confirm ){
            var desc = "Do you really want to delete this " + suffix + "?<br>This process cannot be undone.";
            warningModal("Are you sure?", desc, "cancel", "delete " + suffix, function (button) {
                if (button == 'ok') {
                    doRowDelete( url, hTableList );
                }
            });
        } else {
            doRowDelete( url, hTableList );
            hTableList.trigger("refresh");
        }
    }

    function doRowDelete( url, hTableList ){
        $.get(url, function(data, status){
            if ( status == 'success' ) {
                hTableList.DataTable().draw();
            } else {
            }
        });
    }

    function checkBoxClicked( src, model, row, hTableList ){
        var checked = src.is(':checked');
        var evtjcs = src.data('evtjcs');
        var url = window.contextPath + "/clickAction.json";
        url = addUrlParameter( url, 'evtjcs', evtjcs )
        var pkName = model.pkName;
        var pkValue = row[pkName];
        url = addUrlParameter( url, 'pkValue', pkValue )
        url = addUrlParameter( url, 'checked', checked ? "true" : "false" );
        console.log( 'url: ' + url + '' );
        $.ajax({
            url : url,
            type: 'POST',
            async: false
        }).done(function(responseData) {
            responseData = JSON.parse(  responseData );
            if (responseData.refresh) {
                hTableList.DataTable().draw();
                redrawListTable();
            }
        });
    }

    function getColumnFromModel( model, columnName ){
        if (model.columns) {
            for (var i = 0; i < model.columns.length; i++) {
                var obj = model.columns[i];
                if (obj.name == columnName) {
                    return( obj );
                }
            }
        }
        return( null );
    }

    $('body').on('click', '.hTableDownload', function (e) {
        e.preventDefault();
        var target = $( e.target );
        var downloadID = target.data( 'download-id' );

        /** TODO globals in here need to use locals **/
        var url = window.contextPath + "/tableDownload";
        var dataTableConfig = $(".hTablePanel").attr("data-config");
        var dtConfigJSON = JSON.parse(dataTableConfig);

        url = addUrlParameter( url, 'evtjcs', downloadID );

        var cnstParams = dtConfigJSON.cnstParams;
        for (var name in cnstParams ){
            console.log( 'cnst name: ' + name + ' = ' + cnstParams[name] );
            url = addUrlParameter( url, 'cnst_' + name, cnstParams[name] );
        }

        console.log( dataTableData );

        url = addUrlParameter( url, 'order[0][column]', dataTableData.order[0].column );
        url = addUrlParameter( url, 'order[0][dir]', dataTableData.order[0].dir );

        $('.hTableFilterField').each(function(){
            var name = $(this).prop( 'name' );
            var value = dataTableData[name];
            url = addUrlParameter( url, name, value );
        });

        console.log( 'url: ' + url + '' );
        window.location.replace( encodeURI( url ) );
    });


    /** Todo MAKE THIS FUNCTION LOCAL LATER **/
// Works only with modal.js only
    $('body').on('formsaved','form',function(e,data){
        if(data.response.refresh) {
            redrawListTable();
        }

    });

    //Stop Datatable throwing alerts
    $.fn.dataTable.ext.errMode = 'throw';
    
    $.fn.hTable = function (options) {
        // default settings
        var settings = $.extend({
            iconCallback: function(){},

            example: "test" //Don't need options yet, but we might later
        }, options);

//        settings.iconCallback();

        return this.each(function () {
            var hTable = $(this),
                config = JSON.parse($(this).attr("data-config")),BokCo
                mainconfig = JSON.parse($(this).attr("data-mainconfig")),
                hTableList = $(hTable).find(".hTableList");
            var totalColumns = parseInt( config.totalColumns );

            function getDatatableData(config,mainconfig) {
                var columns = [];
                var columnsJson = config.columns;
                $(columnsJson).each(function(i,col){
                    if(col.visible == "true") {
                        columns.push(
                            {
                                "data": function(row, type, val, meta){
                                    return processValue( mainconfig, config, row, col.name, row[col.name], i);
                                },
                                className: col.name + "Column",
                                orderable : col.orderable
                            }
                        );
                    }
                });

                return columns;
            }

            console.log( 'config.sortColumn:' + config.sortColumn + "'" );
            console.log( config.sortColumn );
            console.log( 'config.sortDir:' + config.sortDir + "'" );
            console.log( config.sortDir );


            hTableList.DataTable({
                "processing": true,
                "serverSide": true,
                "ordering": config.orderable,
//               "order": [[ 3, "desc" ]]
                 "order": [[ config.sortColumn, config.sortDir ]],
                "stateSave": false,
                "autoWidth":false,
                "searching":false,
                "lengthChange": false,
                "paging":config.paging,
                "ajax": {"url": window.contextPath + config.dataUrl,
                    "data": function ( d ) {
                        $(config.filterFields).each(function(i,e){
                            var filter = e;
                            // Below ID must be unique for multiple datatables on the same page.
                            d[filter.id] = $("#" + filter.htmlID).val();
                        });
                        d.currentTab = window.currentTab;
                        dataTableData = d;
//                        console.log( '(d)' );
  //                      console.log( d );

                    }
                },
                "columns": getDatatableData(config,mainconfig),
                "rowCallback": function(row, data, index) {
                    $(row).prop( 'id', "rowID-" + data.rowID );
                    $(row).attr( 'data-pid', data.pkID );

                    /** check if row needs to be highlighted **/
                    var thisDataTable = $(row).parents('.hTablePanel').find('.dataTable');
                    var dataTableConfig = ($(this).parents(".hTablePanel").attr("data-config"));
                    var dtConfigJSON = JSON.parse(dataTableConfig);
                    var pkName = dtConfigJSON.bookingID;
                    for ( id of dtConfigJSON.highLightedIDs ){
                        if ( data['pkID'] == id ){
                            $(row).addClass( 'is-highlighted' );
                        }
                    }

                },
                "createdRow": function (row, data, dataIndex) {
                    $(row).attr('data-policyID', data.mainID);
                },
                "drawCallback": function( settings ) {
                    console.log( '...makeModalLinks...' );
                    makeModalLinks(this);
                }
            });


            //Need to create an ON ERROR here
            hTableList.on('error.dt',function(e, settings, techNote, message ){
                e.preventDefault();
                //If invalid JSON then probably due to session expiry or similar. Reload to force login.
                if(techNote==1){
                    location.reload();
                }
                console.log(e);
                console.log(settings);
                console.log(techNote);
                console.log(message);
            })


            //Setup any Date Picker Range Filters
            hTable.find(".hTableDatePickerField").daterangepicker({
                autoUpdateInput: false,
                locale: {
                    cancelLabel: 'Clear',
                    "format": "DD/MM/YYYY"
                },
                parentEl: '#dateRangePicker-container',
                alwaysShowCalendars:true,
                ranges: {
                    'Today': [moment(), moment()],
                    'Tomorrow': [moment().add(1, 'days'), moment().add(1, 'days')],
                    'Next 14 Days': [moment().add(1, 'days'), moment().add(14, 'days')],
                    'Next Week': [moment().startOf('week').add(1, 'week'), moment().endOf('week').add(1, 'week')],
                    'Next  Month': [moment().startOf('month').add(1, 'month'), moment().endOf('month').add(1, 'month')]
                },
                showCustomRangeLabel:false,
                template:
                    '<div class="daterangepicker">' +
                    '<div class="drp-calendar left">' +
                    '<div class="cal-title">From</div>' +
                    '<div class="calendar-table"></div>' +
                    '<div class="calendar-time"></div>' +
                    '</div>' +
                    '<div class="drp-calendar right">' +
                    '<div class="cal-title">To</div>' +
                    '<div class="calendar-table"></div>' +
                    '<div class="calendar-time"></div>' +
                    '</div>' +
                    '<div class="ranges"><div class="cal-title">Quick Find</div></div>' +
                    '<div class="drp-buttons">' +
                    '<span class="drp-selected"></span>' +
                    '<button class="cancelBtn btn_c" type="button"></button>' +
                    '<button class="applyBtn btn_b" disabled="disabled" type="button"></button> ' +
                    '</div>' +
                    '</div>'
                }).on('apply.daterangepicker', function(ev, picker) {
                $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
                $(this).trigger( "change" );
            }).on('cancel.daterangepicker', function(ev, picker) {
                $(this).val('');
                $(this).trigger( "change" );
            }).on('click',function(){
                $(this).parents(".field-date").addClass('is-open');
            });

            //Setup Filters to trigger a redraw on the datatable
            hTable.find(".hTableFilterField").on('change', function() {
                hTableList.DataTable().draw();
            });

            //Setup tab's to trigger datatable redraw
            hTable.find( ".hTableTab" ).on('click',function(e) {
                e.preventDefault();
                e.stopImmediatePropagation();
                var target = $(e.target);
                target.siblings(".hTableTab-on").removeClass("hTableTab-on");
                target.addClass("hTableTab-on");
                window.currentTab = target.attr("data-tab");
                hTableList.DataTable().draw();
            });

            //If there are no selected tabs, then set "All" to be selected.
            if($('.hTableTab-on').length == 0 ){
                var firstTab = hTable.find( ".hTableTab:first-child" );
                firstTab.addClass("hTableTab-on");
                window.currentTab = firstTab.attr("data-tab");
            }

            //Setup expand links to open out the child row.
            hTable.on('click', '.expand', function (e) {
                e.preventDefault();
                var tr = $(this).closest('tr');
                var thisDataTable = $(this).parents('.hTablePanel').find('.dataTable');
                var row = $(thisDataTable).DataTable().row(tr);
                var dataTableConfig = ($(this).parents(".hTablePanel").attr("data-config"));
                var dtConfigJSON = JSON.parse(dataTableConfig);
                var columnTotal = dtConfigJSON.columns.length;
                expandOrContract( tr, row, columnTotal );
            });

            hTable.on('click', '.deleteLink', function (e) {
                e.preventDefault();
                var tr = $(this).closest('tr');
                var thisDataTable = $(this).parents('.hTablePanel').find('.dataTable');
                var row = $(thisDataTable).DataTable().row(tr);
                var dataTableConfig = ($(this).parents(".hTablePanel").attr("data-config"));
                var dtConfigJSON = JSON.parse(dataTableConfig);
                console.log( 'deleting' );
                deleteClicked( $(this), dtConfigJSON, row.data(), hTableList );
            });

            hTable.on('click', '.hCheckboxAjax', function (e) {
                var tr = $(this).closest('tr');
                var thisDataTable = $(this).parents('.hTablePanel').find('.dataTable');
                var row = $(thisDataTable).DataTable().row(tr);
                var dataTableConfig = ($(this).parents(".hTablePanel").attr("data-config"));
                var dtConfigJSON = JSON.parse(dataTableConfig);
                checkBoxClicked($(this), dtConfigJSON, row.data(), hTableList);
            });


            hTable.on('click', '.ajaxButton', function (e) {
                e.preventDefault();
                var url = window.contextPath + $(this).data('urlpath');
                $.get(url, function(responseData){
                        if ( responseData.refresh ){
                            redrawListTable();
                        }
                    });
               });
            });

    };
}(jQuery));

$( document ).ready(function() {
    $("#defaultHTable").hTable();
});

/**
 * form method not just h-table
 */
function showResponseMessages( form, jsonResponse ){
    form.find('input, select, textarea').removeClass( 'error');
    //TODO Probably want to switch out this for a different "msg" class to follow previously used convention
    var responseMessage = form.find('.responseMessage');
    responseMessage.removeClass('error');
    responseMessage.removeClass('info');
    responseMessage.html("");

    form.find('.form-field').each(function() {
        $(this).removeClass( 'error' );
        $(this).removeClass( 'info' );
    });

    if (jsonResponse.responseMessage) {
        responseMessage.html(jsonResponse.responseMessage.replace('\n', "<br>"));
    }

    if ( jsonResponse.status == 'Success' ) {
        responseMessage.addClass('info');
    }
    if ( jsonResponse.status == 'Fail' ) {
        $(".modal-contentWrap").scrollTop(0);
        responseMessage.addClass('error');
//        console.log(jsonResponse.formFields)

        for (var field of jsonResponse.formFields) {
//            console.log(field)
            var thisField = form.find( '[name="' + field.name + '"]');
            var fieldWrap = thisField.parents(".form-field");
            if(fieldWrap.length === 0){
                fieldWrap = thisField.parents(".radio_vehicle");
            }
            var validation = fieldWrap.find(".field-validation");
//            console.log(thisField);
            if(field.error){
                fieldWrap.addClass( 'error');
            }
            validation.text(field.message);
        }

        for (var message of jsonResponse.messages) {
            var name = message.name;
            if( $('#' + name).length ){
                $('#' + name).html( message.description );
            }
        }

        if ( jsonResponse.redirectURL ){
            console.log( 'redirectURL: ' + jsonResponse.redirectURL + '' );
            window.location.href = jsonResponse.redirectURL;
        }
    }
//    console.log(jsonResponse.messages)

}

function addUrlParameter( url, name, value, row ){
    var end = '';
    if ( url.includes( ' ' ) ){
        var split = url.split( ' ' );
        url = split[0];
        end = ' ' + split[1];
    }

    if (url.includes('?')) {
        if (!url.endsWith('?') && !url.endsWith('&')) {
            url += "&";
        }
    } else {
        url += "?";
    }

    if (url.includes('?')) {
        if (!url.endsWith('?') && !url.endsWith('&')) {
            url += "&";
        }
    } else {
        url += "?";
    }

    if ( row ) {
        if (value.startsWith('#')) { // # prefix means it is the column name of a value in the row object
            value = value.substr(1 );
        } else {
            value = row[value];
        }
    }
    url += name + '=' + value + end;

    return( url );
}

function getResponseValue( jsonResponse, name ){
    for (var obj of jsonResponse.values) {
        if ( obj.name == name ){
            return( obj.value );
        }
    }
}

function redrawListTable( id ){
    id = id ? id : "defaultHTable";
    $( "#" + id + "List" ).DataTable().draw();
    console.log( 'redrawListTable: ' + id + '' );
}


// function test(){
//     console.log( 'start' );
//     console.log( $("#defaultHTableList") );
//     console.log( 'end' );
// }

