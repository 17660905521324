/**
 * submitViaAjax
 * Replaces default form submission behaviour with AJAX. Expect JSON in return, which it passes to a form saved event.
 * @param e form submitted event
 */
function submitViaAjax(e){
    console.log( 'AJAX SUBMIT' );
    var form = $(e.target),
        url = form.attr("action");

    console.log(url);

    $.ajax({
        url : url,
        type: 'POST',
        data : form.serialize()
    }).done(function(responseData){
        console.log( responseData );
        showResponseMessages( form, responseData );
        if ( responseData.refresh ){
            redrawListTable();
        }
        if ( responseData.status == 'Success' ){
            if ( responseData.controllerMethodID == 'addNewPassenger' ) {
                $(".modalType-managePassengers").removeClass("is-modaloffcanvas-open");
                redrawListTable( 'mpHTable' );
            }

            console.log("Form success!");
            console.log(form);
            form.trigger('formsaved',{'response': responseData});
            if ( responseData.redirectURL ){
                console.log( 'redirectURL: ' + responseData.redirectURL + '' );
                window.location.href = responseData.redirectURL;
            }
        }
    });

}

function cancelForm(e){
    var form = $(e.target);
    1
}
