/*!
 *  Stunnplate modal.js
 *  Version: 2.0.1
 */
/*
 *  Last Updated: 21/10/16
 *  By: Mark
 *
 *  Notes:
 *
 *
 */

// New modal layout
//
(function ($) {

    $.fn.modal = function (options) {
        var settings = $.extend({
            modalTemplate: '<div id="modal-ajax" class="modal-wrap">'
                +   '<div class="modal-overlay">'
                +       '<div class="loader"></div>'
                +       '<div class="modal-contentWrap">'
                +       '<div class="modal-close"><svg class="icon-cross"><use xlink:href="/img/icons.svg#icon-cross"></use></svg></div>'
                +           '<div class="modal-content"></div>'
                +           '<div class="modal-footer"></div>'
                +       '</div>'
                +   '</div>'
                +'</div>',
            loadTarget: null,
            loadMethod: "ajax", //Either onPage or ajax
            thisModal : $("#modal-ajax"),
            modalContent : ".modal-content",
            type : null,
            class : null,
            onclick : true,
            onclickFn : null,
            customCallback : null
        }, options);

        return this.each(function () {

            var $this = $(this),
                loadTarget = $this.attr('href') || settings.loadTarget,
                loadMethod = $this.data("loadMethod") || settings.loadMethod, //Either onPage or ajax
                type  = $this.data("modaltype") || settings.type,
                modalClass = $this.data("modalclass") || settings.class,
                trigger = $this,
                $thisModal = $this.data("thismodal") || settings.thisModal,
                onclick = $this.data("onclick") || settings.onclick,
                onclickFn = $this.data("onclickFn") || settings.onclickFn,
                modalContent = $this.data("modalcontent") || settings.modalContent;


            // console.log(settings.modalTemplate);

            var setTarget = function(newTarget) {

                if(newTarget.indexOf("#") == 0){ // This is for when the modal content is on page, may need to add backwards compatability for when whole modal is on page
                    loadTarget = newTarget;
                    loadMethod = "onPage";
                }
                else{
                    var ext = newTarget.split('.').pop().toLowerCase();

                    //Target is image
                    if($.inArray(ext, ['gif','png','jpg','jpeg']) > 1) {
                        loadMethod = "image";
                        loadTarget = newTarget;
                    } else{

                        // Target is selecting part of another page
                        if(newTarget.indexOf("#") > 0){

                            var idIndex = newTarget.indexOf("#"),
                                paramIndex = newTarget.indexOf('?'),
                                targetID = newTarget.substring(idIndex, paramIndex != -1 ? paramIndex : newTarget.length);

                            if(paramIndex > idIndex){
                                loadTarget = newTarget.replace(targetID,"") + " " + targetID;
                            } else{
                                loadTarget = newTarget.replace(/#/," #");
                            }

                        } else{
                            // Target is selecting whole of another page, so just grab the section tags, we don't want head etc.
                            loadTarget = newTarget + " section";
                        }

                    }
                }
            };
            var open = function(){ // Args: ?,callback

                    window.activeModalCtrl = $this;

                    if(loadMethod === "ajax"){
                        // Check if modal code exists on page
                        if($("body #modal-ajax").length == 0){
                            // If it doen't then write modal dialog to page
                            $thisModal = $(settings.modalTemplate);
                            $("body").append($thisModal);
                            //console.log($thisModal);
                        } else{
                            $thisModal = $("body #modal-ajax");
                        }
                        $("body #modal-ajax").addClass("modalType-" + type);
                    }
                    // Add class to page and set body overflow to hidden
                    $('body').addClass('modal-open');
                    $("body").css("overflow","hidden");

                    // Add class to modal if necessary
                    if(loadMethod === "ajax"){
                        $("#modal-ajax").addClass("active");
                    }
                    $thisModal.addClass(modalClass);
                    // Add close listeners
                    $('.modal-close').off('click touchend').on('click touchend',function () {
                        close();
                    });
                    $('.modal-close-btn').off('click touchend').on('click touchend',function () {
                        close();
                    });

                    $('.modal-contentWrap').off('formsaved').on('formsaved','form',function (e) {
                        close();
                    });

                    $('.modal-contentWrap').off('formreset').on('formreset','form',function (e) {
                        close();
                    });

                    // $('.modal-overlay').off('click touchend').on('click touchend',function (e) {
                    //     var target = e.target;
                    //
                    //     if($(target).hasClass("modal-overlay")){
                    //         close();
                    //     }
                    // });

                    $(".modal-contentWrap").off('refresh').on('refresh', function(){
                        fix();
                        loadContent();
                    });

                    // Bind keys
                    bindKeydown();

                    // Type based open methods
                    if (typeof methodsOpen[type] == "function") {
                        methodsOpen[type]($this);
                    }else{
                        //console.warn("Cannot find function "+functionName);
                    }

                    // Trigger modal open event
                    $this.trigger("opened");

                    // Load content
                    loadContent();

                },
                close = function(){

                    //window.activeModalCtrl // - Maybe reset this?

                    console.log( '*** CLOSE ***' );
                    $(".modal-contentWrap").scrollTop(0);
                    $(".modal-contentWrap form").trigger("reset");


                    $(".modal-wrap.active").removeClass("active loaded");
                    $(document).off('keydown');
                    $('.modal-contentWrap').off('formsaved','form');

                    // Remove class from body and set overflow to visible
                    $("body").css("overflow","visible");
                    $('body').removeClass('modal-open');

                    // Remove class from modal
                    $thisModal.removeClass(modalClass);
                    // $(".modal-contentWrap.is-login").removeClass("is-login");

                    //Unbind keys
                    unbindKeydown();

                    // Type based open methods
                    if (typeof methodsClose[type] == "function") {
                        methodsClose[type]($this);
                    } else{
                        //console.warn("Cannot find function "+functionName);
                    }

                    // Trigger modal open event
                    $this.trigger("closed");

                },
                bindKeydown = function(clickOnRtn){
                    //bind key events so we can close the dialogue
                    $(document).bind('keydown', function(e){
                        // e.preventDefault();
                        var key = e.which || e.keyCode || e.charCode;
                        switch (key){
                            case 27: // [esc]
                                $(".modal-wrap.active").removeClass("active loaded");
                                $(document).unbind('keydown');
                                break;
                        }
                    });

                },
                unbindKeydown = function(){
                    //unbind key events
                    $(document).unbind('keydown'); // Probably not what we want - make modal specific
                },
                loadContent = function(){
                    // console.log("Loading content using method: "+loadMethod)
                    if (typeof methodsLoad[loadMethod] == "function") {
                        methodsLoad[loadMethod]($this);
                    }else{
                        // console.warn("There is no such load method: " + loadMethod );
                    }
                },
                setOnClickFn = function(fn) {
                    onClickFn = fn;
                },
                methodsLoad = {
                    ajax : function(){

                        $("#modal-ajax").find(modalContent).first().load(loadTarget, function(responseText, statusText, xhr) {
                            if(statusText == "error"){

                                // Type based error methods
                                if (typeof methodsError[type] == "function") {
                                    methodsError[type]($this);
                                }else{
                                    $("#modal-ajax").find(modalContent).html("<h1>Sorry, an error has occured: " + xhr.status + " - " + xhr.statusText);
                                }
                                // console.log("ERROR")
                            }

                            if(statusText == "success") {
                                modalLoaded();

                                // Type based success methods
                                if (typeof methodsSuccess[type] == "function") {
                                    methodsSuccess[type]($this);
                                }else{
                                    //console.warn("Cannot find function "+functionName);
                                }

                                // console.log($("#modal-ajax").find(modalContent));

                                $this.trigger("loaded");
                            }
                        });
                    },
                    onPage : function(){
                        //
                        $(loadTarget).addClass("active");

                        modalLoaded();
                        // Type based success methods
                        if (typeof methodsSuccess[type] == "function") {
                            methodsSuccess[type]($this);
                        }else{
                            //console.warn("Cannot find function "+functionName);
                        }
                        $this.trigger("loaded");
                    },
                    image : function(){
                        //
                        $("#modal-ajax").find(modalContent).html("<img src='" + loadTarget + "' />");
                        modalLoaded();
                        // Type based success methods
                        if (typeof methodsSuccess[type] == "function") {
                            methodsSuccess[type]($this);
                        }else{
                            //console.warn("Cannot find function "+functionName);
                        }
                        $this.trigger("loaded");
                    }
                },
                modalLoaded = function(){
                    $("#modal-ajax").addClass("loaded");
                    unfix();
                },
                methodsOpen = {

                },
                methodsClose = {
                    managePassengers : function(){
                        refreshBookingDetailPassengers( $('#mpBookingID').html() );
                        // console.log( '**** ddd: ' + $('#mpBookingID').html() + '' );

                        // STEFS CODE HERE
                        // console.log("Manage passengers close");
                    }
                },
                methodsSuccess = {
                    login : function(){
                        $(modalContent + " .tabbed" ).tabme();
                        // $(".modal-content select" ).select2();

                        if($this.data("defaulttab") === "login") {
                            $('[href="#tabLogin"]').trigger("click");
                        }
                    },
                    carousel : function(){
                        var clickedElIndex = 0;
                        if($this.prop("tagName").toLowerCase() === "li"){
                            clickedElIndex = $this.index(0);
                        }

                        $($thisModal + modalContent).on('transitionend',function(){
                            $($thisModal).find(".carousel:first").trigger('goTo',clickedElIndex);
                            $($thisModal + modalContent).off('transitionend');
                        });
                    },
                    accordion : function(){
                        $(modalContent + " .accordion" ).accordion();
                    },
                    managePassengers : function() {

                        var options = {
                            iconCallback : function(){
                                console.log( '***** iconCallback *****' );
                                //Do stuff here
                            }
                        }


                        // console.log("Manage passengers loaded!");
                        $("#mpHTable").hTable(options);

                        var element = $(".js-choices_passengers")[0];
                        // var options = JSON.parse($(element).attr("data-options"));

                        var thisChoices = new Choices(element, options);

                        // console.log("CHOICES");
                        // console.log(thisChoices);
                        // element.on('change',function(e){
                        //     console.log("CHANGE!")
                        //
                        // });

                        // console.log($(".js-choices_passengers").first())

                        //Stop modal closing on form submission.
                        $('.modal-contentWrap').off('formsaved');

                        $(".js-choices_passengers").off('change').on('change',function(e){
                            // console.log("attempt to submit form");
                            // console.log($(this).parents("form").first())
                            $(this).parents("form").trigger("submit");
                        });

                        $("#addPassenger").off('formsaved').on('formsaved',function (e,data){
                            // console.log("ADD PASSENGER FORM SAVED");
                            $(this).trigger("refresh");
                        });

                        $("#createPassenger").off('click').on('click',function(e){
                            e.preventDefault();
                            $(this).parents(".modalType-managePassengers").addClass("is-modaloffcanvas-open");

                            // $('.modal-contentWrap').on('click tap',function (e) {
                            //     if($(e.target).parents(".modal-offcanvas-aside").length === 0 && !$(e.target).hasClass("modal-offcanvas-aside")){
                            //         $(this).parents(".is-modaloffcanvas-open").removeClass("is-modaloffcanvas-open");
                            //         $('.modal-contentWrap').off('click tap');
                            //     }
                            // });
                        });

                        //Custom close X behaviour
                        $('.modal-close').off('click touchend').on('click touchend',function () {
                            if($(this).parents(".is-modaloffcanvas-open").length > 0){
                                $(this).parents(".is-modaloffcanvas-open").removeClass("is-modaloffcanvas-open")
                                // $('.modal-contentWrap').off('click tap');
                            } else{
                                close();
                            }
                        });

                        //Custom reset behaviour - off canvas menu:
                        $('.modal-contentWrap').off('formreset').on('formreset','form',function (e) {
                            $(this).parents(".is-modaloffcanvas-open").removeClass("is-modaloffcanvas-open");
                        });
                    },
                    warningModal : function(){
                        $('#warningModal').find('.warning-cancel').off().on('click',function (e) {
                            e.preventDefault();
                            window.warningCallback('cancel');
                        });

                        $('#warningModal').find('.warning-ok').off().on('click',function (e) {
                            e.preventDefault();
                            window.warningCallback('ok');
                        });
                    }
                },
                methodsError = {

                },
                fix = function(){
                    var contentWrap = $("body #modal-ajax .modal-contentWrap").first(),
                        width = contentWrap.outerWidth(),
                        height = contentWrap.outerHeight();

                    // console.log("FIX!")
                    // console.log(width,height);

                    $(contentWrap).css({
                        'max-width': width,
                        'min-width': width,
                        'max-height':height,
                        'min-height':height
                    });
                },
                unfix = function(){
                    // console.log("UNFIX!")
                    var contentWrap = $("body #modal-ajax .modal-contentWrap").first();
                    setTimeout(() => {
                        $(contentWrap).css({
                            'min-width':'initial',
                            'min-height':'initial'
                        });
                    }, 1500);
                    setTimeout(() => {
                        $(contentWrap).css({
                            'max-width':'90%',
                            'max-height':'90%'
                        });
                    }, 2000);
                };

            // Check for custom on click behaviour, otherwise go ahead with default
            if(onclick) {

                // Add listeners for modal links
                $this.on('click touchend',function(e){
                    e.preventDefault();

                    if (typeof onclickFn === "function") {
                        onclickFn.call();
                    } else{
                        // Ensure that the load target is correctly formatted and the correct load method has been set
                        setTarget(loadTarget);

                        // One we have a target, open the modal
                        open();
                    }

                });

            }

            setTarget(loadTarget);

            if( typeof settings.customCallback == "function") {
                $this.on('callCustom',settings.customCallback());
            }

            return {
                open: open,
                setTarget : setTarget,
                setOnClickFn : setOnClickFn
            }
        });
        //requires jQuery so we dont initialise until dom ready
    };

}(jQuery));

$(function(){
    $(".modal-link").modal();
});

function makeModalLinks(container){
    //Makes links inside a datatable only... - this is ok as it'rs done on a redraw ca;lback
    container.find(".modal-link").modal();
}


/* Example Usage:
 elem.modal({
 loadTarget: "<THE URL YOU WANT>",
 type : "login",
 class : "is-login",
 onclick : false,
 customCallback : <CALLBACK FUNCTION>
 });
 */