/*
	Define page specific scripts here which will be loaded by _domreadyutil.js based on the ID and class of the body tag 
	of any page
 */

// The utility converts dashed classnames to camelCase as we can't use dashes in our variables here so resourcesPage = resources-page

PAGESCRIPT = {
    common: {

        init: function () {


            sniffbrowser();

            // $('#timepicker').datetimepicker({
            //     format:'DD/MM/YYYY HH:mm',
            //     value: new Date()
            // });

                $( "body" ).on("change","select", function(e) {
                    e.preventDefault();
                    clearError( $(this), true );
                });

                $( "body" ).on("change","input", function(e) {
                    e.preventDefault();
                    clearError( $(this), false );
                });

                function clearError( field, select ){
                    var fieldWrap = field.parents(".form-field")
                    if( !select && fieldWrap.length === 0){
                        fieldWrap = field.parents(".radio_vehicle");
                    }
                    var validation = fieldWrap.find(".field-validation");

                    if ( fieldWrap.hasClass( 'error' ) ){
                        if ( select || field.val().trim() != '' ){
                            fieldWrap.removeClass( 'error');
                            validation.text("");
                        }
                    }

                }

            if ($("a[href^='#']").length > 0) {
                $("#main a[href^='#']").not(".modal-click").on("click", function (e) {
                    e.preventDefault();
                    var href = $(this).attr("href");
                    var scrollToPos = $(href).offset().top;
                    var headerHeight = $(".header").outerHeight();
                    scrollToPos = scrollToPos - headerHeight;
                    $('body,html').animate({
                        scrollTop: scrollToPos
                    }, 800);
                    return false;
                });
            }

            function toggleMobileMenu(e){
                e.preventDefault();
                e.stopPropagation();

                $("body").toggleClass("is-offcanvas-open");
                $(".submenu-opened").removeClass("submenu-opened");
                $(".submenu").removeAttr( 'style' );

                var eventtype = 'click';
                document.addEventListener( eventtype, function(e){bodyClickFn(e);} );
                return false;
            }

            $(".off-canvas-nav").on("reset", function(){
                $("body").removeClass('is-offcanvas-open');
                $(".offCanvasMenu-tabtops .tabtrigger").first().trigger("click");
            });

            $("#mobile-nav-close").click(function () {
                $("body").removeClass("is-offcanvas-open");
                $(".submenu-opened").removeClass("submenu-opened");
                // $(".submenu").removeAttr('style');
                // $(".hidden-box").removeClass("hidden-box-opened").removeAttr('style');
                // $(".main-nav-search, .main-nav-login, .hidden-box-sibling").removeClass("hidden-box-opened");
                $(".off-canvas-nav").trigger("reset");
            });

            var container = document.getElementById( 'page-container' ),
                // event type (if mobile use touch events)
                //eventtype = 'click',
                resetMenu = function() {
                    $("body").removeClass('is-offcanvas-open');
                },
                bodyClickFn = function(evt) {

                if( $(evt.target).hasClass("is-offcanvas-close") || $(evt.target).closest('.off-canvas-nav').length === 0 && $(evt.target).closest('.off-canvas-controls').length === 0) {
                        // console.log("RESET");
                        //resetMenu();
                        $(".off-canvas-nav").trigger("reset");
                    }

                };

            $("#mobile-menu-button,.off-canvas-open").on("click", toggleMobileMenu);
            $(".submenu-trigger").on('click',openSubmenu);
            $(window).on('resize', closeMobileMenu);


            /*-------------------------------------------------------*/
            /*- FORM VALIDATION                                     -*/
            /*-------------------------------------------------------*/
            //Mark the form fields as answered on change so that we can show validationj styles apprpriately and not before they have been used.
            //https://css-tricks.com/form-validation-ux-html-css/ - Technique used in thie article (:not(:placeholder-shown) is not available in FF and IE yet)

            $('body').on('change', "input, select, textarea", function () {
                var wrapper = $(this).closest("div");

                //if($(this).val().length > 0) {
                if ($(this).val() !== null && $(this).val().length > 0) {//prior to jquery 3.0 $(this).val() will return null for select multiples with zero items selected so we have to test for both
                    wrapper.addClass("is-answered");
                } else {
                    wrapper.removeClass("is-answered");
                }
            });

            $('body').on('submit', "form", function(e){
                if(!$(this).hasClass("form-noajax")){
                    e.preventDefault();
                    submitViaAjax(e);
                }
            });
            $('body').on('reset', "form", function(e){
                if(!$(this).hasClass("form-noajax")){
                    e.preventDefault();
                    cancelForm(e);
                }
            });







            $(function() { $("form.owasp").on("submit", function(e){
                return( owasp( e, $(this), false  ) );
            }) });

            $(function() { $("form.owaspBlank").on("submit", function(e){
                return( owasp( e, $(this), true  ) );
            }) });

            function owasp( e, thisForm, allowBlank ) {
                var pwd = thisForm.find(".owaspPassword").val();
                if ( allowBlank && pwd == ""){
                    return true;
                }
                var valid = isPasswordValid(pwd);
                if(!valid){
                    e.preventDefault();
                    thisForm.find(".owaspText").show();
                    return false;
                }
            }

            function isPasswordValid( password ) {
                var r = /[a-z]/;
                var total = 0;
                /**
                 * Check if lower case characters exist.
                 */
                if (r.test(password)){
                    total++;
                }

                /**
                 * Check if upper case characters exist.
                 */
                r = /[A-Z]/;
                if (r.test(password)){
                    total++;
                }

                /**
                 * Check if digits exist.
                 */
                r = /[0-9]/;
                if (r.test(password)){
                    total++;
                }

                /**
                 * Check if special characters exist.
                 */
                r = /[ !"#$%&'()*+,-.\/:;<=>?@\[\\\]^_`{|}~]/;
                if (r.test(password)){
                    total++;
                }

                if ( total < 3 ){
                    return( false );
                }

                if ( password.length < 10 || password.length > 128 ){
                    return( false );
                }

                /**
                 * check for more than 2 identical characters in a row
                 */
                r = /(.)\1{2}/;
                if (r.test(password)){
                    return( false );
                }

                return( true );
            }

            /* init svg4everybody */
            svg4everybody();

            /* init cookiebar */
            // (function(){
            //     var checkbox = document.getElementById('hideCookieNotice'),
            //         cookieVal = docCookies.getItem("hideCookieNotice");
            //     //NB: chrome wont allow cookies during local testing (by FF will)
            //     if(checkbox){
            //         checkbox.checked = (cookieVal);
            //         checkbox.addEventListener('change', function(event){
            //             if (event.target.checked) {
            //                 //console.log('checked');
            //                 docCookies.setItem("hideCookieNotice","true",365);
            //             } else {
            //                 //console.log('not checked');
            //                 docCookies.removeItem("hideCookieNotice");
            //             }
            //         });
            //     }
            // })();

            /*-------------------------------------------------------*/
            /*- CHOICES (Chosen Replacement for selects)            -*/
            /*-------------------------------------------------------*/
            // Pass single element
            // const element = document.querySelector('.js-choices');
            // $(".js-choices").each(function(i,e){
            //     var options = JSON.parse($(this).attr("data-options"));
            //     var thisChoices;
            //
            //     if (options == null){
            //         thisChoices = new Choices(e);
            //     } else{
            //         thisChoices = new Choices(e,options);
            //     }
            // });



            $(".js-choices").each(function(i,e){
                var options = $(this).attr("data-options");
                var optionsJson = (options != null) ? JSON.parse(options) : null;
                var thisChoices;
                // var defaultOptions = {itemSelectText: ""};
                // itemSelectText
                // $.extend(optionsJson,defaultOptions);

                // console.log(optionsJson);
                if (optionsJson == null){
                    thisChoices = new Choices(e);
                } else{
                    thisChoices = new Choices(e,options);
                }
            });

            function sleep(milliseconds) {
                const date = Date.now();
                let currentDate = null;
                do {
                    currentDate = Date.now();
                } while (currentDate - date < milliseconds);
            }

        },
        finalize: function () {

        },
    },
    homepage: {
        init: function () {

            if ($("#scrollDown").length > 0) {
                $("#scrollDown").on("click", function (e) {
                    e.preventDefault();
                    var scrollToPos = $("#main").offset().top;
                    var headerHeight = $(".header").outerHeight();
                    var salesHeight = $(".sales-accountants-head").outerHeight();
                    scrollToPos = scrollToPos - headerHeight + salesHeight;
                    $('body,html').animate({
                        scrollTop: scrollToPos
                    }, 800);
                    return false;
                });
            }
        },
    },
    driverInvoiceList:{
        init: function () {
            $('body').on('click', '.hCheckboxAjax', function (e) {
                // var target = $( e.target );
                // var checked = target.is(':checked');
                // if ( !checked ){
                //     e.preventDefault();
                // }
            });
        }
    },
    managePassengers:{
        init: function () {
            // console.log("Manage passengers loaded!");
            $("#mpHTable").hTable();

            var element = $(".js-choices_passengers")[0];
            var options = JSON.parse($(element).attr("data-options"));

            var thisChoices = new Choices(element,options);
        }
    },
    bookingList:{
        init: function () {
            $('body').on('formsaved','form',function(e,data){
                var disputed = getResponseValue(data.response, "disputed");
                if ( disputed ){
                    var bookingID = getResponseValue(data.response, "bookingID");
                    $( '#dispute-' + bookingID ).html( 'EDIT DISPUTE' );
                }
            });
        }
    },
    makeABooking: {
        init: function () {

            $('body').on('form-submit','form',function(e,data){
                console.log( 'makeABooking submit' );
            });
            $('body').on('form-error','form',function(e,data){
                console.log( 'makeABooking error' );
            });

            $('body').on('change','#datetimepicker',function(e){
                console.log( 'makeABooking datetimepicker' );
                checkEstimate();
            });

            var startingPoint = "";
            var destinationPoint = "";
            var startingLatLng = "";
            var destinationLatLng = "";

            function isSamePlace(latLng1,latLng2){
                console.log((findDistance(latLng1,latLng2) <= 50));
                return (findDistance(latLng1,latLng2) <= 50);
            }

            function findDistance(latLng1, latLng2) {
                var R = 6371e3; // R is earth’s radius
                var lat1 = latLng1.split(",")[0];
                // starting point lat
                var lon1 = latLng1.split(",")[1]; // starting point lon
                var lat2 = latLng2.split(",")[0];         // ending point lat
                var lon2 = latLng2.split(",")[1];         // ending point lon

                // console.log(latLng1,latLng2,    lat1,lon1, lat2,lon2)

                var lat1radians = toRadians(lat1);
                var lat2radians = toRadians(lat2);

                var latRadians = toRadians(lat2-lat1);
                var lonRadians = toRadians(lon2-lon1);

                var a = Math.sin(latRadians/2) * Math.sin(latRadians/2) +
                    Math.cos(lat1radians) * Math.cos(lat2radians) *
                    Math.sin(lonRadians/2) * Math.sin(lonRadians/2);
                var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

                var d = R * c;

                return d;
                console.log(d)
            }

            function toRadians(val){
                var PI = 3.1415926535;
                return val / 180.0 * PI;
            }

            var element = $(".js-choices_passengers")[0];
            // var options = JSON.parse($(element).attr("data-options"));

            var options = $(element).find("option");
            var choiceOptions = [];
            // console.log(options);

            options.each(addChoices);

            function addChoices(i){
                var thisChoice = {};
                // console.log($(this));

                //Skip the placeholder
                if ($(this).val() != ""){
                    var balloonData =  $(this).data("balloon") || "";
                    thisChoice.value = $(this).val();
                    thisChoice.label = $(this).text();
                    thisChoice.customProperties = {
                        balloon: balloonData
                    }
                    choiceOptions.push(thisChoice)
                }
            }

            const thisChoices = new Choices(element, {
                removeItems: true,
                removeItemButton: true,
                callbackOnCreateTemplates: function(template) {
                    return {
                        item: (classNames, data) => {
                            console.log(data);
                            return template(`
                                <div class="${classNames.item} ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable} 
                                ${data.placeholder ? classNames.placeholder : ''}" data-item data-id="${data.id}" data-value="${data.value}" ${
                                data.active ? 'aria-selected="true"' : ''
                                     } ${data.disabled ? 'aria-disabled="true"' : ''} data-balloon="" data-balloon-pos="down">
                                    ${data.label}
                                </div>
                            `);
                        },

                        //  ***** data balloon *****
                        // } ${data.disabled ? 'aria-disabled="true"' : ''} data-balloon="${data.customProperties.balloon}" data-balloon-pos="down">



                        // choiceList : (classNames,data) => {
                        //     console.log(classNames);
                        //     console.log(data);
                        //     return template(`
                        //             <div class="${classNames.list}"></div><div class="choices-close">XXXXX</div>
                        //     `);
                        // }
                    }
                },
            });

            thisChoices.setChoices(choiceOptions,'value', 'label', true);

            // $(element).on('change',function(e){
            //     resizeChoicesMobile(this);
            // });
            //
            // function resizeChoicesMobile(choices){
            //     var numberOpts = $(choices).find(":selected").length;
            //
            //     $(choices).css("max-height",)
            // }

            // var thisChoices = new Choices(element,options);

            function checkEstimate(result){
                console.log("Check Estimate!")


                if(result){
                    // if (1 > 0){
                    //     return;
                    // }
                    console.log("Dragged!")
                    processRouteAndEstimate(result);
                } else{
                    console.log("Not Dragged!")
                    var pickupLocation = $('#pickupLocation').val();
                    var dropOffLocation = $('#dropOffLocation').val();

                    if ( pickupLocation && dropOffLocation ) {
                        if((pickupLocation != startingPoint) || (dropOffLocation != destinationPoint)){
                            var split, origin, destination;
                            split = pickupLocation.split( "|" );
                            origin = {lat: parseFloat( split[0] ), lng: parseFloat( split[1] ) };
                            split = dropOffLocation.split( "|" );
                            destination = {lat: parseFloat( split[0] ), lng: parseFloat( split[1] ) };

                            var request = {
                                origin: origin,
                                destination: destination,
                                travelMode: 'DRIVING',
                                unitSystem: google.maps.UnitSystem.IMPERIAL,
                                region: 'GB'
                            };

                            directionsService.route(request, function(result, status) {
                                if (status == 'OK') {
                                    directionsRenderer.setDirections(result);
                                    processRouteAndEstimate(result);
                                }
                            });
                        } else{
                            processRouteAndEstimate();
                        }

                    }
                }
            }

            function processRouteAndEstimate(result){
                console.log("ProcessRouteAndEstimate");
                if(result){
                    setDistanceAndDuration(result);
                }
                if(validateBookingForm()){
                    estimateFare();
                }
            }

            function validateBookingForm(){
                console.log("Validate Booking Form");
                var vehicle = $('input[name=vehicle]:checked').val();
                var date = $('#datetimepicker').val();
                var pickUpLocation = $('#pickupLocation').val();
                var dropOffLocation = $('#dropOffLocation').val();
                var pickUpField = $('#pickupField').val();
                var dropOffField = $('#dropOffField').val();

                if (pickUpField != startingPoint){
                    startingPoint = pickUpField;
                }

                if (dropOffField != destinationPoint){
                    destinationPoint = dropOffField;
                }

                if(vehicle && date && pickUpLocation && dropOffLocation){
                    return true;
                } else{
                    return false;
                }
            }

            function setDistanceAndDuration(result){
                console.log("Set Distance and Duration");
                var distance = result.routes[0].legs[0].distance.text;
                var duration = result.routes[0].legs[0].duration.text;
                // console.log(distance, duration)
                $('#distance').val(distance);
                $('#duration').val(duration);
                $('#mDistance').text(distance);
                $('#mDuration').text(duration);
            }

            function estimateFare(){
                console.log("Estimate Fare");
                var url = window.contextPath + "/booking/estimateFare";
                $.ajax({
                    url: url,
                    type: 'POST',
                    data: $("#bookingForm").serialize()
                }).done(function (responseData) {

                    if (responseData.status == 'Success') {
                        console.log("start");
                        console.log(responseData);
                        console.log("---");
                        console.log(responseData.responseMessage);
                        console.log("end");
                        showResponseMessages($("#bookingForm"), responseData);
                        $("#mPrice").html(responseData.responseMessage);
                    } else {
                        // console.log(responseData.responseMessage);
                        $("#mPrice").html(responseData.responseMessage);
                        showResponseMessages( $("#bookingForm"), responseData );
                    }
                });
            }

            // Create the script tag, set the appropriate attributes
            var script = document.createElement('script');
            script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyD3TVAVQ6yL7Dn87LzEzjRVJmsB6c--2ck&libraries=places&callback=initGoog';
            script.defer = true;
            script.async = true;

            var directionsService, directionsRenderer;
            // Attach your callback function to the `window` object
            window.initGoog = function() {
                //Setup Map
                var center = new google.maps.LatLng(52.4773549,-2.0037138);
                var mapOptions = {
                    zoom:12,
                    center: center,
                    disableDefaultUI: true
                }
                var map = new google.maps.Map(document.getElementById('booking-map'), mapOptions);
                // directionsRenderer.setMap(map);


                // Setup Directions fields - pickup/dropoff
                var pickupField = document.getElementById('pickupField');
                var dropOffField = document.getElementById('dropOffField');

                var options = {
                    componentRestrictions: {country: 'uk'}
                };

                var pickupAutocomplete = new google.maps.places.Autocomplete(pickupField, options);
                var dropOffAutocomplete = new google.maps.places.Autocomplete(dropOffField, options);

                // Setup autocomplete on the pickup/dropoff fields
                pickupAutocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);
                dropOffAutocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);

                pickupAutocomplete.addListener('place_changed', function () {
                    console.log(startingPoint)
                    console.log($('#pickupField').val())

                    if($('#pickupField').val() != startingPoint){
                        locationSelected(pickupAutocomplete, $('#pickupLocation'));
                    }
                });

                dropOffAutocomplete.addListener('place_changed', function () {
                    if($('#dropOffField').val() != destinationPoint) {
                        locationSelected(dropOffAutocomplete, $('#dropOffLocation'));
                    }
                });

                // Setup directions service and renderer
                directionsService = new google.maps.DirectionsService();
                directionsRenderer = new google.maps.DirectionsRenderer({
                    draggable: true,
                    map: map

                });
                // console.log("Add Event Listener");
                directionsRenderer.addListener("directions_changed", () => {
                    // console.log("Directions changes!")
                    var result = directionsRenderer.getDirections();
                    checkEstimate(result);
                    // console.log(result);
                    var pickupAddress = result.routes[0].legs[0].start_address;
                    var pickupLatLng = result.routes[0].legs[0].start_location;
                    var dropoffAddress = result.routes[0].legs[0].end_address;
                    var dropoffLatLng = result.routes[0].legs[0].end_location;

                    var pickupField = $('#pickupField');
                    var dropOffField = $('#dropOffField');
                    var pickupLocation = $('#pickupLocation');
                    var dropOffLocation = $('#dropOffLocation');

                    // console.log(pickupAddress,startingPoint)
                    if(pickupAddress != startingPoint){
                        // console.log(pickupAddress,"TRUE");
                        if(!isSamePlace(startingLatLng, pickupLatLng.lat() + "," + pickupLatLng.lng())){
                            // console.log("not same start")
                            if(startingPoint != ""){
                                pickupField.val(pickupAddress);
                            }
                            startingLatLng = pickupLatLng.lat() + "," + pickupLatLng.lng()
                            pickupLocation.val(pickupLatLng.lat() + "|" + pickupLatLng.lng() +"|"+ pickupAddress);
                        } else{
                            // console.log("same start")
                            pickupLocation.val(pickupLatLng.lat() + "|" + pickupLatLng.lng() +"|"+ startingPoint);
                        }
                    }
                    // console.log(dropoffAddress,destinationPoint)
                    if(dropoffAddress != destinationPoint){
                        // console.log(dropoffAddress,"TRUE")
                        if(!isSamePlace(destinationLatLng, dropoffLatLng.lat() + "," + dropoffLatLng.lng())){
                            // console.log("not same end")
                            if(destinationPoint != "") {
                                dropOffField.val(dropoffAddress);
                            }
                            destinationLatLng = dropoffLatLng.lat() + "," + dropoffLatLng.lng()
                            dropOffLocation.val(dropoffLatLng.lat() + "|" + dropoffLatLng.lng() +"|"+ dropoffAddress);
                        } else{
                            // console.log("same end")
                            dropOffLocation.val(dropoffLatLng.lat() + "|" + dropoffLatLng.lng() +"|"+ destinationPoint);
                        }
                        // dropOffField.val(dropoffAddress);
                        // dropOffLocation.val(dropoffLatLng.lat() + "|" + dropoffLatLng.lng() +"|"+ dropoffAddress);
                    }
                });

            };

            // Append the 'script' element to 'head'
            document.head.appendChild(script);


            //Trigger estimate check on change to fields
            $( 'input[name=vehicle], select, #datetimepicker' ).on('change',function(e) {
                console.log("click")
                checkEstimate();
            });

            var minDate = new Date();
            minDate.setDate(minDate.getDate() + 1);
            // console.log("minDate" + minDate);

            var maxDate = new Date();
            maxDate.setMonth(maxDate.getMonth() + 1);

            // console.log("maxDate" + maxDate);

            var datePickerOn = false;

            console.log(window.outerWidth)
            if(window.outerWidth <= 768){
                console.log("date A")
                // console.log("Below on Load")
                if(datePickerOn){
                    // console.log("Below on Load and ON")
                    // $('#datetimepicker').clone().attr("type","datetime-local").insertAfter('#datetimepicker').prev().remove();
                    $('#datetimepicker').attr("type","datetime-local");
                    $('#datetimepicker').datetimepicker('destroy');
                    datePickerOn = false;
                }
            }
            if(window.outerWidth >= 768){
                console.log("date B")
                // console.log("Above on Load")
                if(!datePickerOn){
                    // console.log("Above on Load and OFF")
                    // $('#datetimepicker').clone().attr("type","text").insertAfter('#datetimepicker').prev().remove();
                    $('#datetimepicker').attr("type","text");
                    $('#datetimepicker').datetimepicker({
                        format:'d/m/Y H:i',
                        minDate:minDate,
                        maxDate:maxDate
                    });
                    datePickerOn = true;
                }
            }


            $(window).on('resize',function(e){
                console.log("Resize: " + datePickerOn)
                if(window.outerWidth <= 768){
                    // console.log("Below on resize")
                    if(datePickerOn){
                        // console.log("Below on resize and ON")
                        // $('#datetimepicker').clone().attr("type","datetime-local").insertAfter('#datetimepicker').prev().remove();
                        $('#datetimepicker').attr("type","datetime-local");
                        $('#datetimepicker').datetimepicker('destroy');
                        datePickerOn = false;
                    }
                }
                if(window.outerWidth >= 768){
                    // console.log("Above on resize")
                    if(!datePickerOn){
                        // console.log("Above on resize and OFF")
                        // $('#datetimepicker').clone().attr("type","text").insertAfter('#datetimepicker').prev().remove();
                        $('#datetimepicker').attr("type","text");
                        $('#datetimepicker').datetimepicker({
                            format:'d/m/Y H:i',
                            minDate:minDate,
                            maxDate:maxDate
                        });
                        datePickerOn = true;
                    }
                }
            });

            function locationSelected( autocomplete, target ) {
                var place = autocomplete.getPlace();
                if ( place.geometry ) {
                    var address = '';
                    for( s of place.address_components ){
                        var value = s.long_name;
                        if ( value != 'England' && value != 'United Kingdom' ){
                            if ( address != '' ){
                                address += ', ';
                            }
                            address += value;
                        }
                    }
                    var all = place.geometry.location.lat() + "|" + place.geometry.location.lng() + "|" + address;
                    target.val( all );
                    checkEstimate();
                }
            }

            $( "#estimate" ).on('click',function(e) {
                e.preventDefault();
                var url = window.contextPath + "/booking/estimateFare";

                $.ajax({
                    url : url,
                    type: 'POST',
                    data : $("#bookingForm").serialize()
                }).done(function(responseData) {

                    if (responseData.status == 'Success') {
                        // console.log(responseData);
                        showResponseMessages( $("#bookingForm"), responseData );
                    } else {
                        // console.log(responseData.responseMessage);
                    }
                });
            });

            function resetForm($form) {
                $form.find('input:text, input:password, input:file, select, textarea').val('');
                $form.find('input:radio, input:checkbox')
                    .removeAttr('checked').removeAttr('selected');
            }

            $( "#bookNow" ).on('click',function(e) {
                e.preventDefault();
                var url = window.contextPath + "/booking/makeBooking";

                $( "#bookNow" ).addClass('hide' );
                $( "#bookingAttempt" ).removeClass('hide' );
                var timeoutFunc = setTimeout(function(){ $( "#bookingAttempt" ).addClass('hide' ); $( "#booking" ).removeClass('hide' ); }, 200);

                $.ajax({
                    url : url,
                    type: 'POST',
                    data : $("#bookingForm").serialize()
                }).done(function(responseData) {
                    if (responseData.status == 'Success') {
                        // console.log(responseData);
                        var bookingID = getResponseValue( responseData, 'bookingID' );
                        // console.log( 'bookingID: ' + bookingID + '' );
                        showResponseMessages( $("#bookingForm"), responseData );
                                window.location.href = "/booking/All?hlID=" + bookingID;
                        // warningModal("Booking Complete","Your booking has been made, would you like to book another?", "no", "yes", function (button) {
                        //     console.log(button);
                        //     if (button == 'ok') {
                        //         console.log("OK FIRED")
                        //         console.log($("#bookingForm"));
                        //         resetForm($("#bookingForm"));
                        //         $(".modal-close").trigger("click");
                        //     } else if (button == 'cancel'){
                        //         window.location.href = "/booking/All?hlID=" + bookingID;
                        //         console.log("CANCEL FIRED")
                        //     }
                        // });
                    } else {
                        // console.log(responseData.responseMessage);
                        $( "#bookNow" ).removeClass('hide' );
                        $( "#bookingAttempt" ).addClass('hide' );
                        $( "#booking" ).addClass('hide' );
                        clearTimeout(timeoutFunc);

                        $("#mPrice").val(responseData.responseMessage);;
                        showResponseMessages( $("#bookingForm"), responseData );
                    }
                });
            });



            // Add Passenger Modal Callback event
            // TODO might need to make this a specific form...
            $('body').on('formsaved','form',function(e,data){
                // var name = e.data.response.name;
                // var id = e.data.response.userID;
                // console.log(data);
                var name = getResponseValue(data.response, "name");
                var id = getResponseValue(data.response, "userID");
                console.log(name,id);

                thisChoices.setChoices([{value:id, label: name, selected: true}],'value','label',false);//append(new Option(name, id) );
            });

            element.addEventListener(
                'addItem',
                function(event) {
                    console.log("choice change!")
                    console.log($(this))
                    // hideDropdown
                    console.log(event);
                    thisChoices.hideDropdown();
                    checkEstimate();
                },
                false,
            );

            $(".field-text,.field-datetime").on("click",function(e){
               $(this).find("input").trigger("focus");
            });

            $(".field-text input,.field-datetime input").on("focus",function (e) {

                $(".is-focused").removeClass("is-focused");
                $(this).parent().addClass("is-focused");

                var fieldset = $(this).closest(".fieldset-wrap");

                if(!fieldset.hasClass("is-fsactive")){
                    $(".is-fsactive").removeClass("is-fsactive");
                    fieldset.addClass("is-fsactive");
                }
            });

            // $(".field-text input,.field-datetime input").on("blur",".is-focused",function(e){
            //     console.log($(e.target))
            //     $(this).find(".is-focused").removeClass("is-focused");
            // });

            $(".fieldset-wrap").on('click',function(e){
                $(".is-fsactive").removeClass("is-fsactive");
                $(this).addClass("is-fsactive");
            });


        }
    }
};