

$(function () {
    $( ".hTablePanel" ).on("click",".cancelBooking", function(e) {
        e.preventDefault();
        console.log( 'event' );

        var bookingID = $(this).prop( 'id' ).replace( 'booking-', '' );
        console.log('bookingID: ' + bookingID + '' );
        var desc = "Do you really want to cancel this booking?<br>This process cannot be undone.";
        warningModal( "Are you sure?", desc, "KEEP BOOKING", "CANCEL BOOKING", function(button){
            console.log( 'button: ' + button + '' );
            if ( button == 'ok' ) {
                /** cancel booking **/
                var url = window.contextPath + "/booking/cancelBooking?bookingID="+bookingID;

                $.ajax({
                    url : url,
                    type: 'POST',
                    async: false,
                    data : $("#bookingForm").serialize()
                }).done(function(responseData) {
                    if (responseData.status == 'Success') {
                        console.log(responseData);
                        redrawListTable();
                    }
                });
            }
        });
    });

    window.map = [];
} );


function refreshBookingDetailPassengers( bookingID ){
    var tr = $("tr[data-pid='" + bookingID +"']")
    var pass = tr.next().find('.passengers');

    $.get("/booking/passengerNames?bookingID="+bookingID, function(responseData){
        if ( responseData.status == 'Success' ) {

            var passHtml = "";
            var names = getResponseValue( responseData, 'passengerNames' );
            for (name of names){
                passHtml += passHtml == "" ? "" : "<br>";
                passHtml += name;
            }
            pass.html( passHtml );
        }
    });
}

function expandRow( rowID, template, row ){

    // console.log("EXPAND ROW");
    // console.log( row );
    var mapID = rowID.replace( 'row', 'map' );
    template = template.replace( '@{mapId}', mapID );
    var driverLabel = row.drivers.length > 1 ? "Drivers" : "Driver";
    template = template.replace( '@{driverLabel}', driverLabel );
    template = template.replace( '@{status}', status );
    template = template.replace( '@{pickUpStartDateFmt}', row['pickUpStartDate-1-fmt'] );
    template = template.replace( '@{passengersFmt}', row['passengers-5-fmt'] );
    template = template.replace( '@{disputeButtonText}', row.disputed ? "EDIT DISPUTE" : "MARK AS IN DISPUTE" );
    var editPassengersLink = '';
    if ( row.status == 'Booked' ){
        var url = '/managePassengers/' + row.bookingID + ' #main';
        editPassengersLink = '<a href="' + url + '" class="modal-link" data-modalType="managePassengers"><svg class="icon-edit"><use xlink:href="/img/icons.svg#icon-edit"/></svg></a>';
    }
    template = template.replace( '@{editPassengersLink}', editPassengersLink );

    var status = row.status;
    if ( status == 'Completed' || status == 'In Dispute') {
        template = template.replace( 'disputeRow hide', 'disputeRow' );
    }
    if ( status == 'Draft' || status == 'Booked') {
        template = template.replace( 'cancelRow hide', 'cancelRow' );
    }

    return( template );
}

function expandRowComplete( rowID, row ){
    // console.log("EXPAND ROW COMPLETE");
    // console.log( row );
    // console.log( 'rowID: ' + rowID + '' );
    var expandID = rowID.replace( "row", "expand" );
    var status = row.status;
    if ( status == 'Draft' || status == 'Booked') {
        $('#' + expandID).find( 'span' ).addClass( 'hide' )
    } else {
        $('#' + expandID).find( 'form' ).addClass( 'hide' )
    }

    var mapID = rowID.replace( 'row', 'map' );
    $(".modal-link").modal();
    expandGoog( mapID, row.pickLatitude, row.pickLongitude,row.dropLatitude ,row.dropLongitude, row.pickUp );
}

function expandGoog( id,pickLatitude, pickLongitude, dropLatitude, dropLongitude ,description ){
    console.log( 'showMap' );
    console.log( 'id: ' + id + '' );
    console.log( 'pickLatitude: ' + pickLatitude + '' );
    console.log( 'pickLongitude: ' + pickLongitude + '' );
    console.log( 'dropLatitude: ' + dropLatitude + '' );
    console.log( 'dropLongitude: ' + dropLongitude + '' );

    var center = new google.maps.LatLng(pickLatitude,pickLongitude);
    console.log("Maps:");
    console.log(document.getElementById(id));
    var bounds = new google.maps.LatLngBounds ();
    var mapOptions = {
        zoom:12,
        center: center,
        disableDefaultUI: true
    }


        map[id] = new google.maps.Map(document.getElementById(id), mapOptions);
    var labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var labelIndex = 0;
    var pickLatLng = new google.maps.LatLng(pickLatitude,pickLongitude);
    var pickMarker = new google.maps.Marker({
        position: pickLatLng,
        map: map[id],
        title: 'Pick Up',
        label: 'A'
    });

    var dropLatLng = new google.maps.LatLng(dropLatitude,dropLongitude);
    var dropMarker = new google.maps.Marker({
        position: dropLatLng,
        map: map[id],
        title: 'Drop Off',
        label: 'B'
    });

    bounds.extend (pickMarker.position);
    bounds.extend (dropMarker.position);
    // map[id].setCenter(center);
    map[id].fitBounds(bounds);
}

// window.expandGoog = function() {
//     console.log("map initialised");
//
//     var center = new google.maps.LatLng(52.4773549,-2.0037138);
//     var mapOptions = {
//         zoom:12,
//         center: center
//     }
//
//     window.map = new google.maps.Map(document.getElementById('expandMap'), mapOptions);
//     // directionsRenderer.setMap(map);
// };