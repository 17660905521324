/**
 * modal stuff
 */

window.warningCallback = function(){};

function warningModal(title, description, cancel, ok, callback) {
    window.warningCallback = callback;
    $('#warningModal').find('.title').html(title);
    $('#warningModal').find('.description').html(description);
    $('#warningModal').find('.warning-cancel').html(cancel);
    $('#warningModal').find('.warning-ok').html(ok);

}


$(function () {

    $('body').on('click', '.warning-cancel', function (e) {
        e.preventDefault();
        if ( window.warningCallback ) {
            window.warningCallback('cancel');
            window.warningCallback = null;
        }
    });

    $('body').on('click', '.warning-ok', function (e) {
        e.preventDefault();
        if ( window.warningCallback ) {
            window.warningCallback('ok');
            window.warningCallback = null;
        }
    });

})